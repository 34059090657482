module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"linkImagesToOriginal":false,"quality":80,"withWebp":true}},{"resolve":"@raae/gatsby-remark-oembed","options":{"providers":{"include":[]}}},{"resolve":"gatsby-remark-embed-video","options":{"width":680,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noreferrer"}}],"remarkPlugins":[null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/ishan/Documents/Personal/ishan-blog"},
    },{
      plugin: require('../node_modules/@alec.brunelle/gatsby-theme-novela/gatsby-browser.js'),
      options: {"plugins":[],"contentPosts":"content/posts","contentAuthors":"content/authors","rootPath":"/","basePath":"/","mailchimp":true,"sources":{"local":true,"contentful":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ishan's Blog","short_name":"ID Blog","start_url":"/","background_color":"#f7f0eb","theme_color":"#444444","display":"minimal-ui","icons":[{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icons-da8ba8c7dd628ee79e17c8841f2f3bea/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["385481635"],"pluginConfig":{"head":true,"anonymize":false,"respectDNT":false,"pageTransitionDelay":0,"defer":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
